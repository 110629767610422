var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Indeterminate (tri-state) support"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeIndeterminate) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('code', [_vm._v("<b-form-checkbox>")]), _c('span', [_vm._v(" supports setting this visual indeterminate state via the ")]), _c('code', [_vm._v("indeterminate")]), _c('span', [_vm._v(" prop (defaults to ")]), _c('code', [_vm._v("false")]), _c('span', [_vm._v(" ). Clicking the checkbox will clear its indeterminate state. The ")]), _c('code', [_vm._v("indeterminate")]), _c('span', [_vm._v(" prop can be synced to the checkbox's state by v-binding the ")]), _c('code', [_vm._v("indeterminate")]), _c('span', [_vm._v(" prop with the ")]), _c('code', [_vm._v(".sync")]), _c('span', [_vm._v(" modifier.")])]), _c('b-form-checkbox', {
    attrs: {
      "indeterminate": _vm.indeterminate
    },
    on: {
      "update:indeterminate": function updateIndeterminate($event) {
        _vm.indeterminate = $event;
      }
    },
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }, [_vm._v(" Click me to see what happens ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.toggleIndeterminate
    }
  }, [_vm._v(" Toggle Indeterminate State ")]), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" Checked: "), _c('strong', [_vm._v(_vm._s(_vm.checked))]), _c('br'), _vm._v(" Indeterminate: "), _c('strong', [_vm._v(_vm._s(_vm.indeterminate))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }