var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Basic Checkbox"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("You can have ")]), _c('code', [_vm._v("<b-form-checkbox-group>")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("<b-form-checkbox>")]), _c('span', [_vm._v(" render a browser native checkbox input by setting the "), _c('code', [_vm._v("plain")]), _vm._v(" prop.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "A",
      "plain": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Checked ")]), _c('b-form-checkbox', {
    attrs: {
      "value": "B",
      "plain": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Unchecked ")]), _c('b-form-checkbox', {
    attrs: {
      "value": "C",
      "plain": "",
      "disabled": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Checked disabled ")]), _c('b-form-checkbox', {
    attrs: {
      "value": "D",
      "plain": "",
      "disabled": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Unchecked disabled ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }